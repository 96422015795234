:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-right: env(safe-area-inset-right);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);

  /* color palette */
  --error-color: #df2648;
  --error-bg: #fce9ec;
  --error-border: #e9677f;
  --default-border: #d1d5db;
  --list-bullet-color: #6b7280;

  /* paddings, etc */
  --default-block-padding: 16px;

  /* deprecated, use --border-radius-X variables */
  --default-border-radius: 8px;
  --default-box-shadow: 0 4px 6px -2px #0000000d;
  --large-box-shadow: 0 0 0 1px rgb(0 0 0 / 0.05), 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -2px rgb(0 0 0 / 0.05);
  --elevations-box-shadow: 0 0 0 1px rgb(0 0 0 / 0.05), 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -2px rgb(0 0 0 / 0.05);
  --elevations-small-box-shadow: 0 1px 2px rgb(0 0 0 / 0.05);
  --elevations-medium-box-shadow: 0px 2px 4px rgb(0 0 0 / 0.08), 0px 2px 4px rgb(0 0 0 / 0.02),
    0px 2px 2px rgb(0 0 0 / 0.02);
  --focus-indicator: 0 0 0 2px #fff, 0 0 0 3px #315cea;
  --on-deck-gradient: linear-gradient(106.4deg, #315cea 0%, #773fe2 100%);
  --on-deck-gradient-with-opacity: linear-gradient(106.4deg, #eaeffd 0%, #fceef1 100%);
  --on-deck-gradient-large: linear-gradient(179.99deg, #315cea 0.01%, #773fe2 52.14%, #e35275 100.11%);
  --on-deck-gradient-large-vertical: linear-gradient(90deg, #315cea 0%, #e35275 100%);

  /* colors */
  --color-neutral-700: #0c111f;
  --color-neutral-600: #1b253c;
  --color-neutral-600-040: rgb(27 37 60 / 0.4);
  --color-neutral-600-030: rgb(27 37 60 / 0.3);
  --color-neutral-600-015: rgb(27 37 60 / 0.15);
  --color-neutral-600-005: rgb(27 37 60 / 0.05);
  --color-neutral-500: #202c46;
  --color-neutral-500-015: rgb(49 92 234 / 0.15);
  --color-neutral-400: #4d566b;
  --color-neutral-300: #9096a2;
  --color-neutral-200: #d1d4db;
  --color-neutral-100: #e9eaec;
  --color-neutral-50: #f4f4f6;
  --color-neutral-100-010: #e9eaec;
  --color-neutral-50-050: rgb(244 244 246 / 0.5);
  --color-neutral-50-010: rgb(233 234 236 / 0.1);
  --color-neutral-0: #fff;
  --color-white-0-020: rgb(255 255 255 / 0.2);
  --color-white-0-050: rgb(255 255 255 / 0.5);
  --color-white-0-010: rgb(255 255 255 / 0.1);
  --color-blue-700: #213576;
  --color-blue-600: #2a4ec7;
  --color-blue-500: #315cea;
  --color-blue-500-005: rgb(49 92 234 / 0.05);
  --color-blue-500-010: rgb(49 92 234 / 0.1);
  --color-blue-500-015: rgb(49 92 234 / 0.15);
  --color-blue-500-030: rgb(49 92 234 / 0.3);
  --color-blue-400: #5a7dee;
  --color-blue-300: #adbef7;
  --color-blue-300-020: rgb(173 190 247 / 0.2);
  --color-blue-300-050: rgb(173 190 247 / 0.5);
  --color-blue-250: #ccd7fa;
  --color-blue-200: #eaeffd;
  --color-blue-100: #f5f7fe;
  --color-purple-700: #4b2a8e;
  --color-purple-600: #6436bf;
  --color-purple-500: #773fe2;
  --color-purple-400: #9265e8;
  --color-purple-300: #c9b2f3;
  --color-purple-200: #f1ecfc;
  --color-purple-100: #f8f5fe;
  --color-magenta-600: #c24764;
  --color-magenta-500: #e35275;
  --color-magenta-400: #e97591;
  --color-magenta-300: #f4bac8;
  --color-magenta-200: #fceef1;
  --color-magenta-100: #fef6f8;
  --color-green-600: #21af52;
  --color-green-500: #27ce60;
  --color-green-400: #52d880;
  --color-green-300: #a9ebbf;
  --color-green-200: #e9faef;
  --color-green-100: #f4fdf7;
  --color-red-600: #b71f3b;
  --color-red-500: #df2648;
  --color-red-400: #e9677f;
  --color-red-300: #f2a6b4;
  --color-red-200: #f9d2d9;
  --color-red-100: #fce9ec;
  --color-yellow-700: #aa6412;
  --color-yellow-600: #c77414;
  --color-yellow-500: #f89118;
  --color-yellow-400: #fab261;
  --color-yellow-300: #fccf9c;
  --color-yellow-200: #fde7cd;
  --color-yellow-100: #fef3e6;
  --color-gradient-start: #e35275;
  --color-gradient-center: #773fe2;
  --color-gradient-end: #315be8;
  --color-slack: #441a49;
  --shadow-huge: 0 0 0 1px rgb(0 0 0 / 0.05), 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -2px rgb(0 0 0 / 0.05);
  --shadow-big: 0 2px 4px rgb(0 0 0 / 0.08), 0 2px 4px rgb(0 0 0 / 0.02), 0 2px 2px rgb(0 0 0 / 0.02);
  --shadow-small: 0 1px 2px rgb(0 0 0 / 0.05);
  --border-radius-sm: 3px;
  --border-radius-md: 6px;
  --border-radius-lg: 12px;
  --border-radius-full: 50%;
  --elevation-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --elevation-md: 0 2px 2px 0 rgb(0 0 0 / 0.02), 0 2px 4px 0 rgb(0 0 0 / 0.02), 0 2px 4px 0 rgb(0 0 0 / 0.08);
  --elevation-lg: 0 4px 6px -2px rgb(0 0 0 / 0.05), 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 0 0 1px rgb(0 0 0 / 0.05);
  --outline-blue: 0 0 0 2px var(--color-neutral-0), 0 0 0 3px var(--color-blue-500);
  --outline-error: 0 0 0 2px var(--color-neutral-0), 0 0 0 3px var(--color-red-500);
  --font-family-inter: "Inter", "ui-sans-serif";
  --font-family-raleway: "Raleway", "ui-sans-serif";
  --font-family-courgette: "Courgette", "cursive";
  --error-color-light: #f6c3cc;
  --warning-color-light: #fde1bf;
  --card-hover: #f9f9fb;

  /* background gradient */
  --background-gradient-horizontal: linear-gradient(
    90deg,
    var(--color-magenta-100) 0%,
    var(--color-blue-100) 50%,
    var(--color-blue-200) 100%
  );
  --background-gradient-horizontal-saturated: radial-gradient(
    circle at 0% -100%,
    var(--color-blue-500) 0%,
    var(--color-blue-500) 35%,
    var(--color-magenta-500) 75%,
    var(--color-magenta-500) 100%
  );
  --button-text-gradient: linear-gradient(90deg, #5a7dee 15.7%, #e35275 59.91%);
  --background-gradient-to-neutral-50: linear-gradient(180deg, transparent 50%, var(--color-neutral-50) 100%),
    var(--background-gradient-horizontal);
}
