.root {
  background-color: white;
  box-shadow: 0 4px 12px 0 rgb(18 26 52 / 7%);
}

.dropdown {
  margin-left: auto;
}

.login {
  margin-left: auto;
  padding: 4px;
}
